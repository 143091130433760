import { Component } from '@angular/core';
import { LoadingController, MenuController, NavController, Platform } from '@ionic/angular';
import { PageService } from './services/page.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private initPlugin: boolean;
  private appLoading: HTMLIonLoadingElement;

  urlParts: string[];
  currentPage: string;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private loadingCtrl: LoadingController,
    private pageService: PageService
  ) {
    this.initializeApp();

    // TEMP PRODUCTION ONLY!!!
    console.warn(`🚨 Console output is disabled on production!`);
    console.log = function (): void { };
    console.debug = function (): void { };
    console.warn = function (): void { };
    console.info = function (): void { };
  }

  async initializeApp() {
    await this.platform.ready();
    this.initializePageSubscription();
  }

  initializePageSubscription() {
    this.pageService.urlParts.subscribe({
      next: url => {
        this.urlParts = url;
        this.currentPage = this.urlParts[this.urlParts.length - 1];
        console.log('currentPage: ', this.currentPage);
      },
      error: err => console.log(err)
    });
  }

  isCurrentPage(pages: string[]) {
    for (let page of pages) {
      if (page === this.currentPage) {
        return true;
      }
    }
    return false;
  }

  async goToPage(page: string) {
    await this.navCtrl.navigateRoot(page);
    this.menuCtrl.close('main-menu');
  }

}
